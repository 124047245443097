import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="bp">
            <div className="wrapper bp">
              <header>
                <h1 className="logo">
                  <a href="/">Neutrogena</a>
                </h1>
                <nav>
                  <a href="/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/neutrogena/o-nas/" className="link">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>| </span>
                  <a href="/budte-krasna/" className="section">
                    Buďte krásná
                   </a>
                   
                  <span />
                   
                  <span />
                </nav>
              </header>
              <div className="top">
                <h2>Péče o ruce</h2>
                <div className="breadcrumb">
                  <a href="/">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>&gt;</span>
                  <a href="/budte-krasna/">Buďte krásná</a> <span>&gt;</span>
                  Péče o ruce
                </div>
              </div>
            </div>
          </div>
          <div className="submenu bpmenu">
            <div className="wrapper">
              <a href="/budte-krasna/sucha-pokozka/" className="link">
                Suchá pokožka
              </a>
              <a href="/budte-krasna/pece-o-plet/" className="link">
                Péče o pleť
              </a>
              <a href="/budte-krasna/pece-o-rty/" className="link">
                Péče o rty
              </a>
              <a href="/budte-krasna/pece-o-ruce/" className="current">
                Péče o ruce
              </a>
              <a href="/budte-krasna/pece-o-nohy/" className="link">
                Péče o nohy
              </a>
              <a href="/budte-krasna/pece-o-tlo/" className="link">
                Péče o tělo
              </a>
            </div>
          </div>
          <div className="wrapper">
            <div className="content">
              <a name="aboutAnchor" />
              <section className="first">
                <div className="bg dlonie1">
                  <div className="text full">
                    <h2>Říká se, že ruce prozradí věk ženy</h2>
                  </div>
                  <div className="text left">
                    <p>
                      Pokud se o ně dobře nestaráme, mohou zkreslovat věk ve Váš
                      neprospěch. Takže možná chcete začít o své ruce pečovat,
                      dokud jsou stále mladé.
                    </p>
                    <p>
                      Jak se starat o ruce efektivně a udržet jim mladistvý
                      vzhled co nejdéle? Začněte tím, že budete vybírat
                      nejkvalitnější výrobky pro péči o pokožku vhodnou pro Váš
                      typ pleti!
                    </p>
                    <p>
                      Kůže na dlaních je silnější než na jiných částech těla a
                      je odolnější vůči poškození. Bohužel stejně jako jiné
                      části těla čelí vysychání, podráždění a poranění.
                    </p>
                    <p>
                      Riziko poranění kůže na rukou je dokonce větší než na
                      jiných částech těla, protože jsou neustále vystavovány
                      náročným environmentálním faktorům jako chlad, suchý
                      vzduch, slunce a klimatizace.
                    </p>
                  </div>
                </div>
              </section>
              <section className="last">
                <div className="bg dlonie2">
                  <div className="text right">
                    <h2>Chytrá hydratace</h2>
                    <p>
                      První NEUTROGENA<sup>®</sup> Norská receptura krém na ruce
                      byl vyroben v roce 1972 a brzy učinil ze značky NEUTROGENA
                      <sup>®</sup> ikonu. Dnes nabídka produktů pro péči o ruce
                      zahrnuje: koncentrovaný krém na ruce (parfémovaný a
                      neparfémovaný), krém pro intenzivní regeneraci pro suché a
                      poškozené ruce, rychle se vstřebávající krém na ruce pro
                      ty, co spěchají, a krém proti strárnutí pokožky rukou,
                      který snižuje výskyt pigmentových skvrn.
                    </p>
                    <p>
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </section>
              <div id="fb_71"></div>
            </div>
          </div>
          <div className="bluebar">
            <div className="bar">
              <div className="wrapper">
                <a href="/neutrogena/o-nas/">
                  O ZNAČCE NEUTROGENA<sup>®</sup>
                </a>
                <span>|</span>
                <a href="/neutrogena/norska-receptura/">Norská receptura</a>
                <span>|</span>
                <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
                <span>|</span>
                <a href="/produkty/">
                  Zobrazit produkty NEUTROGENA<sup>®</sup>
                </a>
              </div>
            </div>
          </div>
          <Footer></Footer>
          <script
            dangerouslySetInnerHTML={{
              __html:
                "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
            }}
          />
        </div>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
